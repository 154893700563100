<template>
    <el-dialog center destroy-on-close :visible.sync="dialog" :close-on-click-modal="showClose" :close-on-press-escape="showClose" :show-close="showClose" width="350px">
        <div class="login-box">
            <div class="login-item">
                <el-image :src="proxyLogo" fit="contain" class="login-img" />
            </div>
            <div class="login-item">
                <div class="login-tip">短信登录</div>
            </div>
            <div class="login-item">
                <el-input v-model="phone" placeholder="请输入手机号码" class="login-input" @keyup.enter.native="onSend"></el-input>
            </div>
            <div class="login-item">
                <el-input v-model="code" placeholder="输入4位短信验证码" class="login-input" @keyup.enter.native="onLogin"></el-input>
                <el-button :disabled="btnSendDisabled" class="login-code" @click="onSend">{{ btnSendValue }}</el-button>
            </div>
            <div class="login-item">
                <div class="login-text">验证即登录，未注册将自动创建考研账号</div>
            </div>
            <div class="login-item">
                <el-button type="primary" class="login-button" :loading="btnLoginLogging" @click="onLogin">登录/注册</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Config from "@/api/service";
import Tools from "@/tool";
import store from '@/store'

export default {
    props: {

    },
    data() {
        return {
            dialog: false,
            showClose: false,
            phone: "",
            code: "",
            timer: null,
            count: 60,
            btnLoginLogging: false,
            btnSendDisabled: false,
            btnSendValue: "获取验证码",
        };
    },
    computed: {
        proxyLogo: function () {
            if (store.state.proxyCode) {
                return `${Config.proxy.image}?ProxyCode=${store.state.proxyCode}&ImageIdentity=0`;
            }
        },
    },
    methods: {
        show(showClose) {
            this.dialog = true;
            this.showClose = showClose;
            return new Promise((resolve, reject) => {
                this.$on('login-success', (res) => {
                    resolve(res);
                });
                this.$on('login-failure', (error) => {
                    reject(error);
                });
            });
        },
        hide() {
            this.dialog = false
        },
        //发送验证码
        onSend() {
            if (!Tools.checkPhone(this.phone)) {
                this.$message.warning("请输入正确的手机号码。");
            } else {
                let me = this;
                //发送验证码
                var model = {
                    Phone: this.phone,
                };
                this.$axios
                    .post(Config.sms.sendLoginMessage, model)
                    .then((res) => {
                        //倒计时定时器
                        this.btnSendDisabled = true;
                        this.btnSendValue = "已发送(60s)";
                        this.count = 60;
                        this.timer = window.setInterval(function () {
                            if (me.count <= 0) {
                                window.clearInterval(this.timer);
                                me.btnSendDisabled = false;
                                me.btnSendValue = "获取验证码";
                            } else {
                                me.btnSendValue = "重发(" + --me.count + "s)";
                            }
                        }, 1000);
                        this.$message.success("验证码发送成功。");
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                    });
            }
        },
        //登录
        onLogin() {
            if (!Tools.checkPhone(this.phone)) {
                this.$message.warning("请输入正确的手机号码。");
            } else {
                this.btnLoginLogging = true;
                var model = {
                    UserPhone: this.phone,
                    VerifyCode: this.code,
                    ProxyCode: store.state.proxyCode
                };
                this.$axios
                    .post(Config.user.login, model)
                    .then((res) => {
                        store.commit("setToken", res.data.Data.Token);
                        this.$message.success("登录成功。");
                        this.$emit("login-success");
                        this.dialog = false;
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                    }).finally(() => {
                        this.btnLoginLogging = false;
                    });
            }
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
.login-box {
    width: 100%;
    height: 320px;
}

.login-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.login-img {
    height: 40px;
    width: 200px;
}

.login-tip {
    color: #FE5E03;
    font-size: 16px;
}

.login-tip::after {
    content: '';
    width: 20px;
    display: block;
    margin: 5px auto auto auto;
    border-bottom: 2px solid #FE5E03;
}

.login-text {
    color: gray;
    font-size: 13px;
}

.login-input {
    width: 100%;
}

.login-code {
    margin-left: 10px;
}

.login-button {
    width: 300px;
    height: 40px;
}
</style>