import axios from 'axios'
import store from '@/store'
import Tools from "@/tool"
import Config from "@/api/service"

export default {
    //非豆丁账户，并且访问豆丁域名（保留一年 记录时间：2024.05.27）
    verifyDocinHostName() {
        var hostName = window.location.hostname;
        var proxyCode = store.state.proxyCode;
        if (proxyCode != 'docin' && hostName == 'kaoyan.docin.com') {
            window.location.href = window.location.href.replace('kaoyan.docin.com/pdfreader', 'www.handebook.com');
        }
    },
    //保存代理商编码
    saveProxyCode(paramProxyCode, queryProxyCode) {
        if (paramProxyCode) {
            if (paramProxyCode != store.state.proxyCode) {
                store.commit("setProxyCode", paramProxyCode);
            }
        } else if (queryProxyCode) {
            if (queryProxyCode != store.state.proxyCode) {
                store.commit("setProxyCode", queryProxyCode);
            }
        } else {
            store.commit("setProxyCode", null);
        }
    },
    //上报访问推广记录
    addVisitPopularizeRecord() {
        if (store.getters.isLogin && store.state.proxyCode) {
            var model = {
                ProxyCode: store.state.proxyCode
            };
            return axios.post(Config.record.visitPopularize, model)
        }
    },
    //上报访问网站记录
    addVisitWebsiteRecord() {
        if (store.state.proxyCode) {
            var proxyCode = store.state.proxyCode;
            var nowDate = Tools.getNowDate("yyyy-MM-dd");
            var visitDate = sessionStorage.getItem("visitServerDate");
            var vistProxy = sessionStorage.getItem("visitProxyCode");
            if (nowDate != visitDate || proxyCode != vistProxy) {
                var model = {
                    ProxyCode: proxyCode
                };
                return axios.post(Config.record.visitWebsite, model).then((res) => {
                    sessionStorage.setItem("visitServerDate", nowDate);
                    sessionStorage.setItem("visitProxyCode", proxyCode);
                });
            }
        }
    },
    //上报访问商品记录
    addVisitProductRecord(recordName) {
        if (store.getters.isLogin && store.state.proxyCode) {
            var model = {
                ProxyCode: store.state.proxyCode,
                RecordName: recordName,
            };
            return axios.post(Config.record.visitProduct, model)
        }
    },
    //上报访问学校记录
    addVisitSchoolRecord(data) {
        if (store.getters.isLogin && store.state.proxyCode) {
            var model = {
                ProxyCode: store.state.proxyCode,
                StudentPhone: data.StudentPhone,
                DocumentGuid: data.DocumentGuid,
                UniversityCode: data.UniversityCode,
                CollegeCode: data.CollegeCode,
                MajorCode: data.MajorCode,
                RecordModule: data.RecordModule,
            };
            return axios.post(Config.record.visitSchool, model)
        }
    },
}