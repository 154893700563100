import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Tools from "@/tool"

//服务器接口地址
// axios.defaults.baseURL = 'https://www.handebook.com/pdfreader';
//响应时间
// axios.defaults.timeout = 10000;

// request interceptor(请求拦截器)
axios.interceptors.request.use(config => {
    if (config.method === "get") {
        var body = Tools.getRoutParamsStr(config.params);
    }
    if (config.method === "post") {
        var body = JSON.stringify(config.data);
    }
    let timestamp = Tools.getUtcTimestamp();
    let nonce = Tools.newGuid();
    let application = 'Pdfreader.Web';
    let version = 'V2.2';
    let sign = Tools.sign(timestamp, nonce, application, version, body);
    config.headers['X-Timestamp'] = timestamp;
    config.headers['X-Nonce'] = nonce;
    config.headers['X-Sign'] = sign;
    config.headers['X-Application'] = application;
    config.headers['X-Version'] = version;
    config.headers['X-Token'] = store.state.token;

    return config;
})

// response interceptor（接收拦截器）
axios.interceptors.response.use((response) => {
    if (response.headers['content-type'].indexOf("text/plain") >= 0 ||
        response.headers['content-type'].indexOf("application/json") >= 0) {
        //判断数据类型
        if (Object.prototype.toString.call(response.data) === '[object Object]') {
            var data = response.data;
        } else if (Object.prototype.toString.call(response.data) === '[object ArrayBuffer]') {
            var data = Tools.convertToJson(response.data);
        }
        //抛出错误
        if (data.Success === false) {
            if (data.Code == -402) {
                store.commit("setToken", null);
                Vue.prototype.$loginDialog.show(false)
                    .then((res) => {
                        console.log("登录成功");
                        router.go(0);
                    })
                    .catch((error) => {
                        console.log("登录失败");
                    });
            }
            return Promise.reject(new Error(data.Message));
        }
    }
    return response;
}, (error) => {
    if (error.response) {
        if (error.response.headers['content-type'] && (error.response.headers['content-type'].indexOf("text/plain") >= 0 || error.response.headers['content-type'].indexOf("application/json") >= 0)) {
            if (!error.response.data.Success) {
                return Promise.reject(new Error(error.response.data.Message));
            }
        } else {
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
})

export default axios;