import md5 from 'js-md5';

export default {
    //签名
    sign(timestamp, nonce, application, version, body) {
        let secret = "SV1dLfFDS32DS97jk32Qkjh34";
        let str = secret + "&" + timestamp + "&" + nonce + "&" + application + "&" + version + "&" + body;
        let sign = md5(str).toUpperCase();
        return sign;
    },
}