<template>
  <MTModule ref="mt" v-if="isMobile"></MTModule>
  <PCModule ref="pc" v-else></PCModule>
</template>
  
<script>
import PCModule from "./PC";
import MTModule from "./MT";
import store from '@/store'

export default {
  name: "Login",
  components: {
    PCModule,
    MTModule,
  },
  props: {
  },
  data() {
    return {

    };
  },
  computed: {
    isMobile: function () {
      return store.getters.isMobile;
    },
  },
  methods: {
    show(showClose) {
      if (store.getters.isMobile) {
        return this.$refs.mt.show(showClose);
      } else {
        return this.$refs.pc.show(showClose);
      }
    },
    hide() {
      if (!store)
        return;
      if (store.getters.isMobile) {
        return this.$refs.mt.hide();
      } else {
        return this.$refs.pc.hide();
      }
    },
  },
  mounted() {

  }
};
</script>
<style scoped></style>