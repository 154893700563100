export default {
    //转换HTML显示，识别超链接
    textToHtml(text) {
        if (!text) {
            return "";
        }
        var reg = /((http|https):\/\/[-A-Za-z0-9+&@#()/%?=~_|!:,.;]+[-A-Za-z0-9+&@#()/%=~_|])/g;
        const html = text.replace(reg, function (url) {
            return `<a href="${url}" target="_blank">${url}</a>`;
        });
        return html;
    },
}