import Vue from 'vue'
import App from './App.vue'
import VueClipboards from 'vue-clipboard2'
// import Vant from 'vant'
// import ElementUI from 'element-ui'
import router from './router'
import store from './store'
import axios from './axios'
import jquery from 'jquery'
import md5 from 'js-md5'
import LoginDialog from './views/Login';
import './plugins/element-ui'
import './plugins/vant'
import './assets/iconfont/iconfont.css'
import './theme/element-ui/index.css'
import './theme/element-button/index.css'
import './theme/element-pagination/index.css'
import './theme/element-tabs/index.css'
import './theme/element-image/index.css'
import './theme/van-image-preview/index.css'
import './theme/scroll-bar/index.css'
import 'vant/lib/index.css'

// Vue.use(Vant)
// Vue.use(ElementUI)
Vue.use(VueClipboards)
Vue.config.productionTip = false
Vue.prototype.$md5 = md5
Vue.prototype.$jquery = jquery
Vue.prototype.$axios = axios
Vue.prototype.$model = {}

//登录弹窗
const loginDialog = new Vue(LoginDialog) 
loginDialog.$mount(document.createElement('div'))
document.body.appendChild(loginDialog.$el)
Vue.prototype.$loginDialog = loginDialog
//路由前进
Vue.prototype.push = (name, params) => {
  Vue.prototype.$model.action = "push";
  router.push(name, params);
};
//路由后退
Vue.prototype.back = () => {
  Vue.prototype.$model.action = "back";
  let path = router.currentRoute.path;
  if (path == "/") {
    return;
  }
  router.back();
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//修改标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
