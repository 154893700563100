import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Tools from "@/tool";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    proxyCode: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setProxyCode(state, proxyCode) {
      state.proxyCode = proxyCode;
    },
  },
  getters: {
    isMobile(state) {
      return Tools.isMobile();
    },
    isLogin(state) {
      return !Tools.isEmpty(state.token);
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage
  })]
})
