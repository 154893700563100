export default {
    //下载BLOB
    downloadBlob(blob) {
        var a = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        a.style.display = 'none'
        a.href = url
        a.click()
        window.URL.revokeObjectURL(a.href)
    },
    //下载URL
    downloadUrl(url) {
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.click()
        window.URL.revokeObjectURL(a.href)
    },
}