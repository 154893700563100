export default {
  formatDate(date, format) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const formatCodes = {
      "yyyy": year,
      "MM": month,
      "dd": day,
      "HH": hours,
      "mm": minutes,
      "ss": seconds
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, match => formatCodes[match]);
  },
  convertDate(str) {
    // str = str.replace(/-/g, "/");
    return new Date(str);
  },
  getNowDate(format = 'yyyy-MM-dd HH:mm:ss') {
    return this.formatDate(new Date(), format);
  },
  getUtcTimestamp() {
    return Math.floor((new Date()).getTime() / 1000);
  },
}