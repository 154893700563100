// import pinyin from "js-pinyin"
import { pinyin, customPinyin } from 'pinyin-pro';

//优先使用自定义拼音
customPinyin({
    长: 'chang',
});

export default {
    sortPinyinMap(arr) {
        
        // 测试多音字，优先使用自定义拼音
        // var yt = pinyin('长', { multiple: false, pattern: 'first', toneType: 'none', type: 'array' });

        let map = new Map();
        //循环列表
        arr.forEach((item) => {
            if (item) {
                //首字母，包括多音字
                let pyArray = pinyin(item.charAt(0), { multiple: false, pattern: 'first', toneType: 'none', type: 'array' });
                pyArray.forEach(py => {
                    py = py.toUpperCase();
                    //分组
                    if (map.has(py)) {
                        map.get(py).push(item);
                    } else {
                        map.set(py, [item]);
                    }
                });
            }
        });
        //首字母排序
        var arrayObj = Array.from(map);
        arrayObj.sort(function (a, b) { return a[0].localeCompare(b[0]) })
        return new Map(arrayObj.map(item => [item[0], item[1]]));
    }
}