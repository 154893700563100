export default {
    //判断字符是否为空的方法
    isEmpty(obj) {
        if (typeof obj === "undefined" || obj === null || obj === "") {
            return true;
        } else {
            return false;
        }
    },
    //复制剪贴板
    copyText(text) {
        if (!text)
            return;
        const input = document.createElement("textarea"); //创建一个textarea框获取需要复制的文本内容
        input.setAttribute('readonly', 'readonly');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        var ret = document.execCommand("Copy");
        input.remove();
        if (ret)
            console.log(text + "复制成功。");
        else
            console.log(text + "复制失败。");
        return ret;
    },
    //对象排序
    sort(data) {
        var arr = [];
        for (var key in data) {
            arr.push(key)
        }
        arr = arr.sort()
        var sortData = {}
        for (var i in arr) {
            var itemKey = arr[i]
            sortData[itemKey] = data[itemKey]
        }
        return sortData;
    },
    //移除对象中值为null、undefined的字段
    removeNullField(obj) {
        var newObj = {};
        if (typeof obj == "string") {
            obj = JSON.parse(obj);
        }
        if (obj instanceof Array) {
            newObj = [];
        }
        if (obj instanceof Object) {
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr) && obj[attr] !== null && obj[attr] !== undefined) {
                    if (obj[attr] instanceof Object) {
                        newObj[attr] = removeNullField(obj[attr]);
                    } else if (typeof obj[attr] == "string" && ((obj[attr].indexOf("{") > -1 && obj[attr].indexOf("}") > -1) || (obj[attr].indexOf("[") > -1 && obj[attr].indexOf("]") > -1))) {
                        try {
                            var attrObj = JSON.parse(obj[attr]);
                            if (attrObj instanceof Object) {
                                newObj[attr] = removeNullField(attrObj);
                            }
                        } catch (e) {
                            newObj[attr] = obj[attr];
                        }
                    } else {
                        newObj[attr] = obj[attr];
                    }
                }
            }
        }
        return newObj;
    },
    //获取URL参数
    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null)
            //return unescape(r[2]);//会中文乱码
            return decodeURI((r[2]));//解决了中文乱码
        return null;
    },
    //检查是否支持本地存储
    checkLocalStorage() {
        try {
            if (!window.localStorage) {
                throw new Error("您的浏览器不支持本地缓存，请更换浏览器或升级。");
            }
            localStorage.setItem("localStorageTest", "localStorageTest");
            if (!localStorage.getItem("localStorageTest")) {
                throw new Error("您已禁用本地缓存，请先开启本地缓存再继续使用。");
            }
        } catch (e) {
            throw new Error("请先开启本地缓存。\n错误信息：" + e.message);
        } finally {
            localStorage.removeItem("localStorageTest");
        }
    },
    //检查是否在微信内部
    isWechat() {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    },
}