<template>
  <div id="app">
    <router-view id="view" to="/"></router-view>
  </div>
</template>
<script>
import Manager from "@/manager"

export default {
  name: "App",
  watch: {
    "$store.state.token": {
      handler: function (newVal, oldVal) {
        //用户切换，重新记录代理商用户信息
        Manager.addVisitPopularizeRecord(this.$store.state.proxyCode);
      }
    },
    "$store.state.proxyCode": {
      handler: function (newVal, oldVal) {
        //代理商切换，重新记录代理商用户信息
        Manager.addVisitPopularizeRecord(this.$store.state.proxyCode);
      }
    },
  },
  methods: {
  },
  mounted() {
    
  }
};
</script>

<style>
html,
body,
#app,
#view {
  height: 100%;
  font-family: "微软雅黑";
}
</style>
