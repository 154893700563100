export default {
    //克隆
    clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    //转换json对象
    convertToJson(source) {
        if (source instanceof ArrayBuffer) {
            // 将ArrayBuffer转换为字符串 
            const decoder = new TextDecoder('utf-8');
            const jsonString = decoder.decode(source);
            // 解析JSON字符串为JSON对象 
            return JSON.parse(jsonString);
        }
    },
}