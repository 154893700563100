<template>
    <van-dialog v-model="dialog" :showConfirmButton="false">
        <div class="login-box">
            <div class="login-item">
                <van-image :src="proxyLogo" fit="contain" class="login-img" />
            </div>
            <div class="login-item">
                <div class="login-tip">短信登录</div>
            </div>
            <van-cell-group inset>
                <van-field v-model="phone" type="tel" label="手机号：" placeholder="请输入手机号" @keyup.enter.native="onLogin" />
                <van-field v-model="code" type="digit" center clearable label="验证码：" placeholder="请输入短信验证码" @keyup.enter.native="onLogin">
                    <template #button>
                        <van-button round size="small" type="primary" :disabled="btnSendDisabled" @click="onSend">{{ btnSendValue }}</van-button>
                    </template>
                </van-field>
                <div class="login-item">
                    <div class="login-text">验证即登录，未注册将自动创建考研账号</div>
                </div>
                <div style="margin: 15px">
                    <van-button round block color="linear-gradient(to right, #ff6034, #ee0a24)" :loading="btnLoginLogging" loading-text="登录中..." @click="onLogin">登录/注册</van-button>
                </div>
            </van-cell-group>
        </div>
    </van-dialog>
</template>
<script>
import Config from "@/api/service";
import Tools from "@/tool";
import store from '@/store'

export default {
    props: {

    },
    data() {
        return {
            dialog: false,
            showClose: false,
            phone: "",
            code: "",
            timer: null,
            count: 60,
            btnLoginLogging: false,
            btnSendDisabled: false,
            btnSendValue: "获取验证码",
        };
    },
    computed: {
        proxyLogo: function () {
            if (store.state.proxyCode) {
                return `${Config.proxy.image}?ProxyCode=${store.state.proxyCode}&ImageIdentity=0`;
            }
        },
    },
    methods: {
        show(showClose) {
            this.dialog = true;
            this.showClose = showClose;
            return new Promise((resolve, reject) => {
                this.$on('login-success', (res) => {
                    resolve(res);
                });
                this.$on('login-failure', (error) => {
                    reject(error);
                });
            });
        },
        hide() {
            this.dialog = false
        },
        //发送验证码
        onSend() {
            if (!Tools.checkPhone(this.phone)) {
                this.$toast.fail("请输入正确的手机号码。");
            } else {
                let me = this;
                //发送验证码
                var model = {
                    Phone: this.phone,
                };
                this.$axios
                    .post(Config.sms.sendLoginMessage, model)
                    .then((res) => {
                        //倒计时定时器
                        this.btnSendDisabled = true;
                        this.btnSendValue = "已发送(60s)";
                        this.count = 60;
                        this.timer = window.setInterval(function () {
                            if (me.count <= 0) {
                                window.clearInterval(this.timer);
                                me.btnSendDisabled = false;
                                me.btnSendValue = "获取验证码";
                            } else {
                                me.btnSendValue = "重发(" + --me.count + "s)";
                            }
                        }, 1000);
                        this.$toast.success("验证码发送成功。");
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //登录
        onLogin() {
            if (!Tools.checkPhone(this.phone)) {
                this.$toast.fail("请输入正确的手机号码。");
            } else {
                this.btnLoginLogging = true;
                var model = {
                    UserPhone: this.phone,
                    VerifyCode: this.code,
                    ProxyCode: store.state.proxyCode,
                };
                this.$axios
                    .post(Config.user.login, model)
                    .then((res) => {
                        store.commit("setToken", res.data.Data.Token);
                        this.$toast.success("登录成功。");
                        this.$emit("login-success");
                        this.dialog = false
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    }).finally(() => {
                        this.btnLoginLogging = false;
                    });
            }
        },
    }
};
</script>

<style scoped>
.login-box {
    width: 100%;
}

.login-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.login-img {
    height: 30px;
}

.login-tip {
    color: #FE5E03;
    font-size: 16px;
}

.login-tip::after {
    content: '';
    width: 20px;
    display: block;
    margin: 5px auto auto auto;
    border-bottom: 2px solid #FE5E03;
}

.login-text {
    color: gray;
    font-size: 13px;
}

.login-input {
    width: 100%;
}

.login-code {
    margin-left: 10px;
}

.login-button {
    width: 300px;
    height: 40px;
}

::v-deep .van-field__label {
    width: auto;
    margin-right: 0px;
}
</style>