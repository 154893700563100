export default {
  //获取路由参数字符串
  getRoutParamsStr(params) {
    var keys = [];
    var model = {};
    var paramstr = "";
    for (var paramkey in params) {
      if (params[paramkey] != null && params[paramkey] != undefined) {
        keys.push(paramkey);
      }
    }
    // keys.sort();
    for (var i = 0; i < keys.length; i++) {
      var itemkey = keys[i];
      if (typeof (params[itemkey]) === 'object') {
        model[itemkey] = JSON.stringify(params[itemkey]);
      } else {
        model[itemkey] = params[itemkey];
      }
      if (i === keys.length - 1) {
        paramstr += itemkey + "=" + model[itemkey];
      } else {
        paramstr += itemkey + "=" + model[itemkey] + "&";
      }
    }
    return paramstr;
  },
}