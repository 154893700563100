import md5 from 'js-md5';
import comm from "./comm"

export default{
    //组装body
    buliderBody(model) {
        var model = comm.removeNullField(model);
        var model = comm.sort(model);
        var json = JSON.stringify(model);
    
        var header = {};
        header.Application = "PdfReader.Web";
        header.Version = "V1.0.1.0";
        header.SecretKey = "SJFDHKNVNCISOPQLMCKSIA";
        header.Sign = md5(json + "SV1dLfFDS32DS97jk32Qkjh34").toUpperCase();
        /*
        时间：2021.12.05
        说明：暂时不需要一次性和时间戳校验。
        原因：参数改变会导致GET请求文件缓存失效。
        解决办法：后续采用原生header添加头验证
        */
        // header.Timestamp = new Date().Format("yyyy-MM-dd HH:mm:ss");
        // header.Nonce = Guid.NewGuidToUpperCase();
        var body = {
            Header: header,
            Body: model
        }
        return body;
    },
}