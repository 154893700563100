import Vue from 'vue'
import VueRouter from 'vue-router'
import Manager from "@/manager"
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    // base: process.env.NODE_ENV == 'development' ? './' : '/app/web/', //判断本地环境还是打包环境
    // mode: 'history',

    //页面路由跳转
    routes: [
        //每个链接都有代理编号的方式
        {
            path: '/:proxyCode/index',
            name: 'Index',
            component: () => import('../views/Index'),
            children: [{
                path: '/:proxyCode/home',
                name: 'Home',
                meta: { active: '01', navshow: true },
                component: () => import('../views/Home')
            }, {
                path: '/:proxyCode/document',
                name: 'Document',
                meta: { active: '02', navshow: true, keepAlive: true },
                component: () => import('../views/Document')
            }, {
                path: '/:proxyCode/document/:documentGuid',
                name: 'Document1',
                meta: { active: '02', navshow: false, keepAlive: true },
                component: () => import('../views/Document1')
            }, {//兼容保留一年 记录时间：2024.01.28
                path: '/:proxyCode/documents',
                name: 'Document',
                meta: { active: '02', navshow: true, keepAlive: true },
                component: () => import('../views/Document')
            }, {
                path: '/:proxyCode/school',
                name: 'School',
                meta: { active: '03', navshow: true, keepAlive: true },
                component: () => import('../views/School')
            }, {
                path: '/:proxyCode/school/:universityCode',
                name: 'School1',
                meta: { active: '03', navshow: true },
                component: () => import('../views/School1')
            }, {
                path: '/:proxyCode/school/:universityCode/:collegeCode/:majorCode/:majorLearnway',
                name: 'School2',
                meta: { active: '03', navshow: true },
                component: () => import('../views/School2')
            }, {
                path: '/:proxyCode/major',
                name: 'Major',
                meta: { active: '04', navshow: true, keepAlive: true },
                component: () => import('../views/Major')
            }, {
                path: '/:proxyCode/major/:majorCode',
                name: 'Major1',
                meta: { active: '04', navshow: true, keepAlive: true },
                component: () => import('../views/Major1')
            }, {
                path: '/:proxyCode/article',
                name: 'Article',
                meta: { active: '05', navshow: true, keepAlive: true },
                component: () => import('../views/Article')
            }, {
                path: '/:proxyCode/article/:articleGuid',
                name: 'Article1',
                meta: { active: '05', navshow: true },
                component: () => import('../views/Article1')
            }, {
                path: '/:proxyCode/aiSelection',
                name: 'AiSelection',
                meta: { active: '06', navshow: true, keepAlive: true },
                component: () => import('../views/AiSelection')
            }, {
                path: '/:proxyCode/smartAdjust',
                name: 'SmartAdjust',
                meta: { active: '07', navshow: true, keepAlive: true },
                component: () => import('../views/SmartAdjust')
            }, {
                path: '/:proxyCode/gradeRank',
                name: 'GradeRank',
                meta: { active: '08', navshow: true, keepAlive: true },
                component: () => import('../views/GradeRank')
            }, {
                path: '/:proxyCode/nationalLine',
                name: 'NationalLine',
                meta: { active: '09', navshow: true, keepAlive: true },
                component: () => import('../views/NationalLine')
            }, {
                path: '/:proxyCode/adjust',
                name: 'Adjust',
                meta: { active: '10', navshow: true, keepAlive: true },
                component: () => import('../views/Adjust')
            }, {
                path: '/:proxyCode/adjust/:year/:universityCode/:collegeCode/:majorCode/:majorLearnway',
                name: 'Adjust1',
                meta: { active: '10', navshow: true },
                component: () => import('../views/Adjust1')
            }, {
                path: '/:proxyCode/admit',
                name: 'Admit',
                meta: { active: '11', navshow: true, keepAlive: true },
                component: () => import('../views/Admit')
            }, {
                path: '/:proxyCode/admit/:universityCode/:collegeCode/:majorCode/:majorLearnway',
                name: 'Admit1',
                meta: { active: '11', navshow: true },
                component: () => import('../views/Admit1')
            }, {
                path: '/:proxyCode/course',
                name: 'Course',
                meta: { active: '12', navshow: true, keepAlive: true },
                component: () => import('../views/Course')
            }, {
                path: '/:proxyCode/course/:courseGuid',
                name: 'Course1',
                meta: { active: '12', navshow: false, keepAlive: false },
                component: () => import('../views/Course1')
            }, {
                path: '/:proxyCode/league',
                name: 'League',
                meta: { active: '13', navshow: true, keepAlive: true },
                component: () => import('../views/League')
            }, {
                path: '/:proxyCode/contact',
                name: 'Contact',
                meta: { active: '14', navshow: true },
                component: () => import('../views/Contact')
            }, {
                path: '/:proxyCode/orderList',
                name: 'OrderList',
                meta: { active: '15', navshow: true },
                component: () => import('../views/OrderList')
            }, {
                path: '/:proxyCode/account',
                name: 'Account',
                meta: { active: '16', navshow: true },
                component: () => import('../views/Account')
            }, {
                path: '/:proxyCode/print',
                name: 'Print',
                meta: { active: '', navshow: true },
                component: () => import('../views/Print')
            }, {
                path: '/:proxyCode/regist',
                name: 'Regist',
                meta: { active: '', navshow: true },
                component: () => import('../views/Regist')
            }, {
                path: '/:proxyCode/submitDocumentOrder',
                name: 'SubmitDocumentOrder',
                meta: { active: '', navshow: false },
                component: () => import('../views/SubmitDocumentOrder')
            }, {
                path: '/:proxyCode/submitCourseOrder',
                name: 'SubmitCourseOrder',
                meta: { active: '', navshow: false },
                component: () => import('../views/SubmitCourseOrder')
            }, {
                path: '/:proxyCode/submitMemberOrder',
                name: 'SubmitMemberOrder',
                meta: { active: '', navshow: false },
                component: () => import('../views/SubmitMemberOrder')
            }, {
                path: '/:proxyCode/orderDetails',
                name: 'OrderDetails',
                meta: { active: '', navshow: false },
                component: () => import('../views/OrderDetails')
            }]
        }, {
            path: '/:proxyCode/viewer',
            name: 'Viewer',
            component: () => import('../views/Viewer')
        }, {
            path: '/:proxyCode/video',
            name: 'Video',
            component: () => import('../views/Video')
        }, {
            path: '/:proxyCode/payBefore',
            name: 'PayBefore',
            component: () => import('../views/PayBefore')
        }, {
            path: '/:proxyCode/payFinish',
            name: 'PayFinish',
            component: () => import('../views/PayFinish')
        }, {
            path: '/:proxyCode/payWechat',
            name: 'PayWechat',
            component: () => import('../views/PayWechat')
        }, {
            path: '/access',
            name: 'Access',
            component: () => import('../views/Access')
        }, {
            path: '/error',
            name: 'Error',
            component: () => import('../views/Error')
        },


        // {
        //   path: '/',
        //   name: 'Index',
        //   redirect: "/home",
        // },
        // {
        //     path: '/home',
        //     name: 'Home',
        //     component: () => import('../views/index.vue')
        // },
        //移动端
        // {
        //     path: '/mt/home',
        //     name: 'MTHome',
        //     component: () => import('../views/MT/Home.vue')
        // },
        // {
        //     path: '/login',
        //     name: 'MtLogin',
        //     component: () => import('../views/MT/Login.vue')
        // },
        // {
        //     path: '/regist1',
        //     name: 'MTRegist1',
        //     component: () => import('../views/MT/Regist1.vue')
        // },
        // {
        //     path: '/regist2',
        //     name: 'MTRegist2',
        //     component: () => import('../views/MT/Regist2.vue')
        // },
        // {
        //     path: '/order',
        //     name: 'MTOrder',
        //     component: () => import('../views/MT/Order.vue')
        // },
        // {
        //     path: '/print',
        //     name: 'MTPrint',
        //     component: () => import('../views/MT/Print.vue')
        // },
        // {
        //     path: '/contact',
        //     name: 'MTContact',
        //     component: () => import('../views/MT/Contact.vue')
        // },
        // {
        //     path: '/payfinish',
        //     name: 'MTPayFinish',
        //     component: () => import('../views/MT/PayFinish.vue')
        // },
        // {
        //     path: '/access',
        //     name: 'MTAccess',
        //     component: () => import('../views/MT/Access.vue')
        // },
        // {
        //     path: '/viewer1',
        //     name: 'MTViewer1',
        //     component: () => import('../views/MT/Viewer1.vue')
        // },
        //保留一年，2024年删除。记录时间2023-05-05，2024-01-08已删除
        // {
        //     path: '/viewer2',
        //     name: 'MTViewer2',
        //     component: () => import('../views/Old/Viewer2.vue')
        // },
        // {
        //     path: '/exampaper1',
        //     name: 'MTExampaper1',
        //     component: () => import('../views/MT/Exampaper1.vue')
        // },
        // {
        //     path: '/exampaper2',
        //     name: 'MTExampaper2',
        //     component: () => import('../views/MT/Exampaper2.vue')
        // },
        // {
        //     path: '/error',
        //     name: 'MTError',
        //     component: () => import('../views/MT/Error.vue')
        // },
        //保留一年，2025年删除。记录时间2024-02-28 保留链接：https://kaoyan.docin.com/pdfreader/web/#/home?proxyCode=1234
        {
            path: '/',
            name: 'Index',
            component: () => import('../views/Index'),
            children: [{
                path: '/home',
                name: 'Home',
                meta: { active: '01', navshow: true },
                component: () => import('../views/Home')
            },
                // {
                //     path: '/viewer',
                //     name: 'Viewer',
                //     meta: { active: '', navshow: false },
                //     component: () => import('../views/Viewer')
                // }
            ]
        },
        // {
        //     path: '/payBefore',
        //     name: 'PayBefore',
        //     component: () => import('../views/PayBefore')
        // }, {
        //     path: '/payFinish',
        //     name: 'PayFinish',
        //     component: () => import('../views/PayFinish')
        // },
    ],
    //解决页面跳转滚动到顶部
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

//解决路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

//路由跳转前
router.beforeEach(async (to, from, next) => {
    //白名单页面-跳转
    if (Manager.getWhiteListPages().includes(to.name)) {
        next();
    } else {
        //保存代理商编码
        Manager.saveProxyCode(to.params.proxyCode, to.query.proxyCode);
        //身份认证页面-登录窗口
        if (!store.getters.isLogin && Manager.getAuthLoginPages().includes(to.name)) {
            Vue.prototype.$loginDialog.show(false)
                .then((res) => {
                    console.log("登录成功");
                    router.go(0);
                })
                .catch((error) => {
                    console.log("登录失败");
                });
        }
        //跳转
        next();
    }
})

//路由跳转后
router.afterEach((to, from, next) => {
    //上报访问推广记录
    Manager.addVisitPopularizeRecord();
    //上报访问网站记录
    Manager.addVisitWebsiteRecord();
    //非豆丁账户，并且访问豆丁域名（保留一年 记录时间：2024.05.27）
    Manager.verifyDocinHostName();
})

export default router